<template>
    <div class="bkColor">
        <headTitle :title="title" :routerPath="'/pjCollection'"></headTitle>
        <van-form validate-first @failed="onFailed" @submit="submitForm" ref='form'>
            <van-field clickable name="picker" :value="pjMsg" label="项目信息" placeholder="点击选择项目" @click="showA = true"
                :rules="[{ required: true, message: '项目信息不能为空' }]" required />
            <van-popup v-model="showA" position="bottom">
                <!-- <van-picker show-toolbar :columns="pjOption" value-key="label" @confirm="pjCollectionConfirm"
                    @cancel="showA = false" /> -->
                <div style="
            width: 100%;
            box-sizing: border-box;
            padding: 10px;
            display: flex;
            justify-content: space-between;
          ">
                    <div @click="showA = false">取消</div>
                    <div style="color: #576b95" @click="pjCollectionConfirm">确认</div>
                </div>
                <van-search class="search" v-model="pjSearch" placeholder="请输入项目名称" shape="round" show-action
                    @input="onPjSearch" @cancel="onPjCancel" action-text="清空" />
                <van-picker :columns="pjArrOption" ref="pjPicker" value-key="label" @cancel="showA = false" />
            </van-popup>
            <van-field name="radio" label="收款单位（请以发票抬头一致）" required>
                <template #input>
                    <van-radio-group v-model="queryFrom.belong" direction="horizontal">
                        <van-radio :name="1">大美自然</van-radio>
                        <van-radio :name="2">中创科技</van-radio>
                        <van-radio :name="3">广云仓</van-radio>
                        <van-radio :name="4">大丰年</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field label="填写人" :value="userMsg" readonly />
            <van-field v-model="queryFrom.price" type="number" label="收款金额（元）" placeholder="请输入"
                :rules="[{ required: true, message: '项目金额不能为空' }]" required />
            <van-field name="radio" label="是否保证金" required>
                <template #input>
                    <van-radio-group v-model="queryFrom.guarantee" direction="horizontal">
                        <van-radio :name="1">是</van-radio>
                        <van-radio :name="0">否</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field v-model="queryFrom.collectionTime" is-link readonly label="收款时间" placeholder="请选择收款时间"
                @click="showC = true" :rules="[{ required: true, message: '收款时间不能为空' }]" required />
            <van-popup v-model="showC" round position="bottom">
                <van-datetime-picker v-model="currentDate" type="date" title="收款时间" @confirm="chooseTime"
                    @cancel="showC = false" />
            </van-popup>
            <van-field v-model="queryFrom.description" rows="2" autosize label="收款说明" type="textarea" maxlength="800"
                placeholder="请输入收款说明" show-word-limit />
            <van-field name="uploader" label="附件">
                <template #input>
                    <van-uploader v-model="uploader" @click-preview="viewFile" accept="file" upload-icon="plus"
                        :after-read="afterRead" :before-read="beforeRead" @delete="deleteFile"
                        :preview-full-image="false" />
                </template>
            </van-field>
            <!-- <process @judgeList="getJudgeList" :avatar-list="selectList" :routerPath="'/newPjCollection'"
                :isCollection="true">
            </process> -->
            <van-button round block type="info" native-type="submit" :disabled="uploading" :loading="isLoading"
                loading-text="加载中...">提交</van-button>
        </van-form>
    </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import {
    Field,
    Cell,
    Popup,
    DatetimePicker,
    Picker,
    Uploader,
    Button,
    Toast,
    Form,
    Search,
    RadioGroup,
    Radio
} from "vant";


import { gatheringAdd } from '../api/gathering'
import { fileUpload } from "../api/api";
import { timeChange } from '../units/units'

Vue.use(Field);
Vue.use(Cell);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Picker);
Vue.use(Uploader);
Vue.use(Button);
Vue.use(Toast)
Vue.use(Form)
Vue.use(Search)
Vue.use(Radio);
Vue.use(RadioGroup);

import { infoList } from "../api/info";
import { getToken } from "../units/auth";
// import process from "../components/process.vue";

export default {
    name: "newPjCollection",
    components: {
        headTitle,
        // process,
    },
    data() {
        return {
            title: "新建项目收款",
            pjMsg: "", //项目信息显示文字
            pjOption: [], //项目选项
            pjArrOption: [],//项目存放数组
            pjSearch: '',//项目筛选值
            userMsg: "", //用户显示文字
            queryFrom: {
                userId: "", //汇报人
                belong: 1,//项目归属
                projectId: "", //项目信息id
                price: "", //项目金额
                guarantee: 0,//是否保证金 0否 1是
                description: "", //项目简介
                fileList: [], //附件列表
                reviewedList: [], //审核人列表
                collectionTime: ''
            },
            showA: false,
            showB: false,
            showC: false,
            currentDate: new Date(),
            description: "",
            uploader: [], //附件
            uploading: false, //是否正在上传
            clear: false,
            isLoading: false,
            selectArr: [], //默认选择审核人数组
            selectList: [], //选择完审核人后的返回
        };
    },

    mounted() {
        this.getPjOption();
        this.getToken();
    },
    activated() {
        // this.getSecectList()
    },
    beforeDestroy() {
        window.localStorage.removeItem("chooseList")
        window.localStorage.removeItem("copyList");
    },
    methods: {
        //默认审核人流程
        getJudgeList(val) {
            this.selectArr = val
        },
        //获取已选人的列表
        getSecectList() {
            this.selectList =
                JSON.parse(window.localStorage.getItem("chooseList")) || [];
        },
        getToken() {
            this.userMsg = getToken("userName");
        },
        getPjOption() {
            infoList().then((res) => {
                let obj = res.data.rows.map((it) => {
                    return {
                        label: it.projectName,
                        value: it.id,
                        belong: it.belong
                    };
                });
                this.pjOption = this.pjArrOption = obj;
            });
        },
        onPjSearch(val) {
            this.pjArrOption = this.pjOption.filter((it) => {
                if (it.label.indexOf(val) != -1) {
                    return it;
                }
            });
        },
        onPjCancel() {
            this.pjArrOption = this.pjOption;
        },
        pjCollectionConfirm() {
            this.showA = false;
            this.queryFrom.projectId = this.$refs.pjPicker.getValues()[0].value;
            this.pjMsg = this.$refs.pjPicker.getValues()[0].label;
            switch (this.$refs.pjPicker.getValues()[0].belong) {
                case 'DM':
                    this.queryFrom.belong = 1
                    break
                case 'ZC':
                    this.queryFrom.belong = 2
                    break
                case 'GYC':
                    this.queryFrom.belong = 3
                    break
                case 'DFN':
                    this.queryFrom.belong = 4
                    break
                default:
                    this.belong = 1
            }
        },
        beforeRead(file) {
            if (file.type == 'application/msword') {
                Toast('请将.doc类型文件另存为改成.docx类型的文件后再进行上传（请注意:手动改后缀可能文件类型并没有变化）')
                return
            }
            //上传之前校验
            if (file.size > 30 * 1024 * 1024) {
                Toast('只允许上传30M的文件')
                return false
            }
            return true;
        },
        async afterRead(file) {
            //文件读取完成后的回调函数
            file.status = "uploading";
            file.message = "上传中...";
            this.uploading = true;
            let fd = new FormData();
            fd.append("file", file.file);
            fileUpload(fd).then((res) => {
                if (res.data.code == 200) {
                    let obj = {
                        fileName: res.data.originalName,
                        url: res.data.url,
                    };
                    this.uploader[this.uploader.length - 1].url = res.data.url; //给返回的文件添加url属性
                    this.uploader[this.uploader.length - 1].fileName =
                        res.data.originalName;
                    this.queryFrom.fileList.push(obj);
                    file.status = "";
                    file.message = ""; //上传状态
                    this.uploading = false;
                } else {
                    file.status = "failed";
                    file.message = "上传失败";
                    this.uploading = false;
                }
            }).catch(err => {
                if (err) {
                    file.status = "failed";
                    file.message = "上传失败";
                    this.uploading = false;
                }
            });
        },
        deleteFile(file) {
            this.queryFrom.fileList = this.queryFrom.fileList.filter(it => { return it.url !== file.url })
        },
        viewFile(file) {
            if (file.url.indexOf('.jpg') !== -1 || file.url.indexOf('.jpeg') !== -1 || file.url.indexOf('.png') !== -1) {
                return
            }
            this.$router.push({
                path: "/preview",
                query: { url: file.url, routerPath: "/newPjCollection" },
            });
        },
        chooseTime() {
            this.showC = false
            this.queryFrom.collectionTime = timeChange(this.currentDate, true)
            // this.queryFrom.collectionTime = this.queryFrom.collectionTime.replace(/\//g, "-");
        },
        //校验不通过
        onFailed(errorInfo) {
            Toast.fail(errorInfo.errors[0].message)
        },
        submitForm() {
            this.isLoading = true
            gatheringAdd(this.queryFrom).then(res => {
                if (res.data.code == 200) {
                    this.isLoading = false
                    Toast.success("提交成功");
                    this.$router.replace('/pjCollection');
                }
            }).catch(() => {
                this.isLoading = false
            })
        },
    },
};
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    height: 100vh;
    background: #f6f6f6;
}

/deep/ .van-cell {
    flex-wrap: wrap;
    margin-bottom: 2vh;
}

/deep/ .van-field__label,
.van-cell__title {
    width: 100vw;
    font-size: 1rem;
}

/deep/ .van-cell__title {
    -webkit-box-flex: unset;
    -webkit-flex: unset;
    flex: unset;
    text-align: left;
}

/deep/ .van-cell__value {
    text-align: left;
}

.search /deep/ .van-cell {
    margin-bottom: unset;
}
</style>